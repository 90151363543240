.article-content
	line-height: r(24px)
	text-align: justify
	> *
		+ *
			margin-top: r(20px)
	ul
		list-style: disc
		padding-left: 1.25rem
	table
		td,th
			border: 1px solid #dee2e6
			padding: .75rem
			vertical-align: top
	h2
		font-size: r(20px)
		font-weight: 700
	h3
		font-size: r(18px)
		font-weight: 600
	* + h2
		margin-top: r(28px)