.contact-info-wrap
	padding: r(30px) r(20px)
	background-image: url(../img/contact-bg.png)
	background-repeat: no-repeat
	background-size: cover

.contact-info
	padding-right: r(30px)
	h2
		font-weight: bold
		font-size: r(19px)
		line-height: r(28px)
		text-align: center
		color: color(main)
		margin-top: r(10px)
		margin-bottom: r(33px)
	ul
		li
			display: flex
			line-height: 1.5
			em
				margin-right: r(16.5px)
				font-size: r(24px)
				color: color(light-blue)
			+ li
				margin-top: r(20px)
	@media screen and ( max-width: 1024.98px )
		padding-right: 0
		margin-bottom: r(30px)

.contact-map
	margin-top: r(30px)
	iframe
		height: r(520px)
		width: 100%
	@media screen and ( max-width: 1024.98px )
		iframe
			height: r(250px)

.wrap-contact
	.form-group
		input[type="text"],textarea
			height: 50px
			background: #FFFFFF
			border: 1px solid #C8C8C8
			font-size: r(15px)
			width: 100%
			padding: 0 r(20px)
			&::placeholder
				font-size: r(15px)
				color: #999
		textarea
			padding: r(13px) r(20px)
			height: r(200px)
	.frm-btn
		text-align: center
		input
			width: r(155px)
			height: r(45px)
			border: 0
			background-color: color(light-blue)
			color: #fff
			font-size: 16px
			font-weight: 700
			cursor: pointer
	.frm-btn-reset
		display: none
	.frm-captcha
		display: flex
		align-items: center
		flex-direction: row-reverse
		justify-content: space-between
		margin-top: r(30px)
		.label
			display: none
		input
			width: r(320px) !important
			max-width: 100%
		span[style="visibility:hidden;"]
			display: none
		.RadCaptcha
			display: flex
			flex-direction: column-reverse
			> div
				> div
					display: flex
					align-items: center
				a
					font-size: 0
					display: inline-block
					margin: 0 0 0 30px
					&:before
						font-family: "Font Awesome 5 Pro"
					    font-weight: 900
						content: "\f01e"
						font-size: r(40px) !important
						color: #000
						display: inline-block !important
	@media screen and ( max-width: 768.98px )
		.frm-captcha
			width: 100%
			// margin-bottom: r(20px)
			display: block
			.RadCaptcha
				margin-top: 15px
				> div
					> div
						width: 100%
					> p
						margin-top: r(20px)
						width: 100%
			input[type='text']
				width: 100% !important