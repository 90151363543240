.subnav
	margin-bottom: r(60px)
	ul
		text-align: center
		overflow-x: auto
		letter-spacing: -8px
		white-space: nowrap
		li
			display: inline-block
			a
				height: r(55px)
				display: flex
				align-items: center
				background: #F1EFEF
				font-weight: 600
				line-height: r(20px)
				color: #999999
				padding: r(20px) r(50px)
				border-top: 3px solid #fff
				border-bottom: 3px solid #fff
				position: relative
				white-space: nowrap
				letter-spacing: normal
			&:not(:last-child)
				a
					&::after
						position: absolute
						content: ''
						right: 0
						top: 50%
						transform: translateY(-50%)
						width: 2px
						height: r(35px)
						background: #C4C4C4
			&.active
				a
					background-color: color(light-blue)
					border-color: color(light-blue)
					color: #fff
					box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.1)
					&::after
						display: none