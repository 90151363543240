.home-1
	padding-top: r(80px)
	.content-wrap
		background-image: url(../img/home-bg-1.png)
		background-size: cover
		background-repeat: no-repeat
		background-position: bottom center
	.content-left
		padding-bottom: r(100px)
		padding-right: r(14px)
	.content-right
		padding: r(60px) 0 r(60px) r(55px)
		.section-lead
			margin-bottom: r(15px)
		.section-title
			margin-bottom: r(24px)
	.img
		img
			width: 100%
	.numbers
		display: flex
		margin-top: r(40px)
		flex-wrap: wrap
		color: #fff
		margin-left: r(-20px)
		> *
			width: 50%
			padding-left: r(20px)
		.number-item
			.title
				display: flex
				align-items: center
				.text
					font-weight: 700
					font-size: r(36px)
					margin-left: r(15px)
			.brief
				margin-top: r(20px)
				text-transform: uppercase
				line-height: r(22px)
				font-weight: 600
	@media screen and ( max-width: 1024.98px )
		padding: r(40px) 0
		.content-wrap
			background-image: none
		.content-left
			padding-bottom: 0
			padding-top: r(30px)
		.numbers
			color: #333
		.content-right
			padding: 0
		.row
			flex-direction: column-reverse
	@media screen and ( max-width: 576px )
		.numbers
			margin-top: r(20px)
			.number-item
				.title
					.icon
						width: 30px
					.text
						font-size: r(24px)