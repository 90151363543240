.project-item
	display: block
	position: relative
	.project-img
		+img-scale(393 / 600 * 100%)
		position: relative
		&::after
			position: absolute
			content: ''
			bottom: 0
			left: 0
			width: 100%
			height: 70%
			background: linear-gradient(0deg, rgba(0,0,0,0.75) 0%, rgba(0,0,0,0) 70%)
	.project-caption
		position: absolute
		bottom: 0
		left: 0
		width: 100%
		padding: r(24px)
		text-align: center
		color: #fff
	.project-title
		font-weight: 500
		font-size: r(22px)
		line-height: r(27px)
		text-transform: uppercase
	.project-desc
		font-size: r(14px)
		line-height: r(24px)
		margin-top: r(12px)
		span
			margin-right: r(15px)
