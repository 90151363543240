.project-detail-section
	padding-bottom: 0 !important

.project-detail-images
	margin-bottom: 16px
	.img
		+img-scale(569 / 869 * 100%)

.project-detail-thumbnails
	.img
		+img-scale(105 / 160 * 100%)

.project-detail-table
	margin-top: r(40px)
	padding: r(22px) r(40px)
	background: color(main)
	color: #fff
	line-height: 1.375
	table
		width: 100%
		tr
			td
				padding: 18px 0
				&:first-child
					font-weight: 600
					padding-right: 1rem
			+ tr
					border-top: 1px solid rgba(#FFFFFF, 0.25)
	@media screen and ( max-width: 768.98px )
		margin-top: r(30px)
		padding: 0 r(24px)

.project-detail-facility
	padding: r(60px) 0
	background: #f5f5f5
	.facility-list
		margin-top: r(40px)
		.item
			text-align: center
			line-height: r(24px)
			.icon
				margin-bottom: r(20px)
				height: 60px
				display: flex
				align-items: center
				justify-content: center
			.title
				font-size: r(20px)
				font-weight: 700
				color: color(main)
	@media screen and ( max-width: 768.98px )
		padding: r(40px) 0

.project-detail-info
	padding: r(50px) 0 r(117px)
	background-size: cover
	background-repeat: no-repeat
	background-position: center center
	position: relative
	z-index: 1
	&::before
		content: ''
		background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.735592) 53.52%, rgba(255, 255, 255, 0.3) 100%);
		position: absolute
		z-index: -1
		top: 0
		left: 0
		width: 100%
		height: 100%
	ul
		font-weight: 600
		list-style: disc
		padding-left: 1.25rem
		li
			+ li
				margin-top: r(12px)