.about-4
	.item
		color: #fff
		position: relative
		.title
			font-weight: 500
			font-size: r(22px)
			line-height: r(27px)
			text-transform: uppercase
			margin-bottom: r(32px)
		.content
			padding-right: r(100px)
			position: relative
			z-index: 2
		.img
			position: relative
			z-index: 2
	@media screen and ( min-width: 1024.98px )
		.item
			&.background-light-blue
				.row
					flex-direction: row-reverse
				.content
					padding-right: 0
					padding-left: r(100px)
		.item-list
			position: relative
			&::after
				position: absolute
				content: url(../img/bg-about-2.png)
				top: 50%
				left: 50%
				transform: translate(-50%,-50%)
				z-index: 1
				pointer-events: none
	@media screen and ( max-width: 1024.98px )
		.item
			padding: r(40px) 0
			.img
				margin-top: r(30px)
				img
					width: 100%
			.content
				padding-right: 0
			
