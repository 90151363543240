.about-7
	padding-top: 0 !important
	.item
		position: relative
		margin-left: (450 / 1920 * 100%)
		color: #fff
		.img
			position: absolute
			left: r(-105px)
			bottom: r(-48px)
			z-index: 1
		.content
			height: 477px
			padding-left: r(450px)
			padding-right: r(40px)
			display: flex
			flex-direction: column
			justify-content: center
		.text
			max-width: r(670px)
		.title
			font-weight: 500
			font-size: r(22px)
			line-height: r(27px)
			text-transform: uppercase
			margin-bottom: r(24px)
	@media screen and ( min-width: 1024.98px )
		.item
			&:nth-child(2n)
				margin-left: auto
				margin-right: (450 / 1920 * 100%)
				.img
					left: auto
					right: r(-105px)
				.content
					padding-right: r(450px)
					padding-left: (450 / 1920 * 100%)
			.desc
				max-height: 250px
				overflow: auto
				padding-right: 16px
				&::-webkit-scrollbar
					width: 5px
					background: #fff
				&::-webkit-scrollbar-thumb
					background: #999
	@media screen and ( max-width: 1280.98px )
		.item
			margin-left: 5%
			&:nth-child(2n)
				margin-right: 5%
	@media screen and ( max-width: 1024.98px )
		.item
			margin-left: 0
			display: flex
			.img
				position: static
				width: 50%
				img
					width: 100%
					height: 100%
					object-fit: cover
			.content
				width: 50%
				height: auto
				padding: 30px
			&:nth-child(2n)
				margin-right: 0
				flex-direction: row-reverse
	@media screen and ( max-width: 576px )
		.item
			display: block
			.img
				width: 100%
			.content
				width: 100%
			+ .item
				margin-top: r(30px)