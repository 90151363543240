.service-category
	+ .service-category
		margin-top: r(60px)
	
.service-category-heading
	margin-bottom: r(80px)
	.section-title
		margin-bottom: 0
	@media screen and ( max-width: 1024.98px )
		margin-bottom: r(40px)

.service-item
	position: relative
	.service-content
		padding: r(30px) r(20px)
		background: #F5F5F5
	.service-title
		font-weight: 500
		font-size: r(22px)
		line-height: r(27px)
		text-transform: uppercase
		margin-bottom: r(24px)
	.view-more
		margin-top: r(36px)
	@media screen and ( max-width: 1024.98px )
		+ .service-item
			margin-top: r(30px)
	@media screen and ( min-width: 1024.98px )
		.service-img
			top: -40px
			left: 0
			position: absolute
			width: 38%
			height: 100%
			.container
				height: 100%
			.img
				height: 100%
			img
				width: 100%
				height: 100%
				object-fit: cover
			.container
				padding: 0
				max-width: none
		.service-content
			padding: r(95px) r(40px) r(95px) 0
			.row
				justify-content: flex-end
		&:nth-child(2n)
			.service-img
				right: 0
				left: auto
			.service-content
				padding: r(95px) r(40px)
				.row
					justify-content: flex-start
		+ .service-item
			margin-top: 40px


.service-category-btn
	margin-top: r(40px)