.home-5
	.section-title
		margin-bottom: r(24px)
	.swiper-navigation
		display: flex
		margin-top: 20px
		.swiper-btn
			transform: none
			position: static
			margin: 0
			display: flex !important
			+ .swiper-btn
				margin-left: 10px
	.partner-slider
		position: relative
		&:before
			position: absolute
			content: ''
			height: 1px
			width: 100%
			background: #e1e1e1
			top: 50%
			transform: translateY(-50%)
			left: 0
		&:after
			position: absolute
			content: ''
			height: 100%
			width: 2px
			background: #fff
			right: 0
			top: 0
			z-index: 2
		.item
			height: 130px
			display: flex
			align-items: center
			justify-content: center
			border-right: 1px solid #e1e1e1
			padding: 10px 0