.section-title
	font-weight: bold
	font-size: r(36px)
	line-height: r(44px)
	letter-spacing: -0.01em
	text-transform: uppercase
	color: color(main)
	margin-bottom: r(40px)
	@media screen and ( max-width: 1024.98px )
		margin-bottom: r(24px)

.small-title
	font-weight: 600
	font-size: r(30px)
	line-height: r(37px)
	text-transform: uppercase
	margin-bottom: r(40px)
	@media screen and ( max-width: 1024.98px )
		margin-bottom: r(24px)

.line-title
	padding-left: r(14px)
	border-left: 6px solid color(light-blue)

.section-heading
	margin-bottom: r(40px)
	.section-title
		margin-bottom: 0
		padding-right: r(15px)
	.section-lead
		margin-bottom: r(10px)
	@media screen and ( max-width: 768.98px )
		.section-title
			font-size: r(20px)
			line-height: 1.5
		.btn
			padding: 0 r(15px)
			white-space: nowrap