.about-5
	background: #F2F3F5
	.item
		.content
			padding-bottom: r(40px)
	.swiper-container
		margin-top: r(20px)
	.swiper-navigation
		display: flex
		margin-top: 10px
		justify-content: flex-end
		.swiper-btn
			transform: none
			position: static
			margin: 0
			display: flex !important
			+ .swiper-btn
				margin-left: 10px
	.swiper-pagination
		border-bottom: 2px solid #C4C4C4
		bottom: 0
	.swiper-pagination-bullet
		height: auto
		background: transparent
		font-size: r(22px)
		line-height: r(27px)
		font-weight: 500
		color: #C8C8C8
		border-radius: 0
		padding-bottom: 11px
		width: r(96px)
		position: relative
		opacity: 1
		&::after
			content: ''
			position: absolute
			bottom: -2px
			width: 100%
			height: 4px
			background: color(main)
			left: 0
			opacity: 0
			transition: 0.3s all
		&.swiper-pagination-bullet-active
			color: #666
			&::after
				opacity: 1
	@media screen and ( max-width: 1024.98px )
		.swiper-pagination
			display: none
		.item
			.content
				padding-bottom: 0
	@media screen and ( min-width: 1024.98px )
		.item
			.article-content
				max-height: 300px
				padding-right: r(30px)
				overflow-y: auto
				&::-webkit-scrollbar
					width: 5px
					background: #C4C4C4
				&::-webkit-scrollbar-thumb
					background: color(main)
