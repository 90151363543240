.about-8
	background-image: url(../img/bg-about-3.png)
	background-size: cover
	background-repeat: no-repeat
	background-position: bottom center
	.swiper-slide
		height: auto
	.item
		height: 100%
		display: flex
		flex-direction: column
		.img
			+img-scale(400 / 600 * 100%)
		.content
			padding: r(40px) r(40px) r(80px)
			background: #fff
			border-bottom: 4px solid color(light-blue)
			position: relative
			margin-left: r(60px)
			top: r(-60px)
			flex: 1 1 0%
		.title
			font-weight: 600
			font-size: r(24px)
			line-height: r(29px)
			margin-bottom: r(20px)
		.brief
			letter-spacing: -0.035em
			color: #666
			margin-bottom: r(36px)
	@media screen and ( min-width: 1024.98px )
		padding-top: r(40px)
		padding-bottom: r(296px)
	@media screen and ( max-width: 1024.98px )
		.item
			.content
				margin-left: 0
				top: 0
		