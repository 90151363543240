.about-2
	padding-top: r(90px)
	background: color(main)
	color: #fff
	.small-title
		margin-bottom: r(10px)
	.content
		padding-top: r(30px)
		padding-bottom: r(60px)
	.brief
		+line(4)
		p
			&:nth-child(n+1)
				display: none
	.img
		text-align: center
	@media screen and ( max-width: 1024.98px )
		padding: r(40px) 0
		.row
			flex-direction: column-reverse
		.content
			padding-bottom: 0
