.video-slider
	.video-img
		transition: 0.3s all
		position: relative
		background: #000
		display: block
		overflow: hidden
		+img-scale(565 / 930 * 100%)
		img
			width: 100%
			opacity: 0.6
			transition: 0.3s all
		&::after
			content: "\f04b"
			font-family: "Font Awesome 5 Pro"
			position: absolute
			top: 50%
			left: 50%
			transform: translate(-50%,-50%)
			font-weight: 900
			width: r(135px)
			height: r(80px)
			display: flex
			align-items: center
			justify-content: center
			background: rgba(#fff , 0.75)
			border-radius: 5px
			color: rgba(#666 , 0.8)
			font-size: r(40px)
	.video-title
		font-size: r(20px)
		line-height: r(26px)
		text-align: center
		color: color(main)
		margin-top: r(20px)
		font-weight: 700
	@media screen and ( max-width: 1024.98px )
		.swiper-navigation
			display: none
		.video-img
			&::after
				width: r(80px)
				height: r(60px)
				font-size: r(24px)
	@media screen and ( min-width: 1024.98px )
		.swiper-slide-active
			.video-img
				z-index: 2
				img
					opacity: 1
				&::after
					opacity: 1
			.video-title
				opacity: 1
		.swiper-slide-prev,.swiper-slide-next
			.video-img
				transform: scale(0.85)
				&::after
					opacity: 0
			.video-title
				opacity: 0
		.swiper-btn
			&.swiper-prev
				right: auto
				left: 20%
			&.swiper-next
				left: auto
				right: 20%
