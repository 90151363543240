$container-padding: 15px
$container-max-width: 100%
$container-xl-width: 1260px
$container-lg-width: 1110px
$container-md-width: 750px
$container-sm-width: 560px

.container
	width: $container-max-width
	padding-right: $container-padding
	padding-left: $container-padding
	margin-right: auto
	margin-left: auto

	@media screen and ( min-width: map-get($breakpoints, sm) )
		max-width: $container-sm-width
	@media screen and ( min-width: map-get($breakpoints, md) )
		max-width: $container-md-width
	@media screen and ( min-width: map-get($breakpoints, lg) )
		max-width: $container-lg-width
	@media screen and ( min-width: map-get($breakpoints, xl) )
		max-width: $container-xl-width
		