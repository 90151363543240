.breadcrumb-wrapper
	background: #f5f5f5
	.breadcrumb
		display: flex
		align-items: center
		height: 50px
		list-style: none
		padding-left: 0
		margin-bottom: 0
		li
			a
				font-size: 16px
			+ li
				padding-left: 15px
				display: flex
				align-items: center
				&::before
					font-family: "Font Awesome 5 Pro"
					content: "\f054"
					margin-right: 15px
					font-size: 13px

	@media screen and ( max-width: 768.98px )
		display: none