.view-more
	display: inline-flex
	align-items: center
	font-weight: 600
	font-size: 12px
	line-height: 15px
	color: color(main)
	text-transform: uppercase
	span
		margin-left: r(12px)

.swiper-btn
	background: #FFFFFF
	border: 1px solid color(main)
	display: flex
	align-items: center
	justify-content: center
	width: r(48px)
	height: r(48px)
	font-size: r(18px)
	color: color(main)
	position: absolute
	top: 50%
	transform: translateY(-50%)
	cursor: pointer
	z-index: 2
	@media screen and ( min-width: 1024.98px )
		margin: 0 15px
		&.swiper-prev
			right: 100%
		&.swiper-next
			left: 100%
	@media screen and ( max-width: 1024.98px )
		display: none
		&.swiper-prev
			left: -5px
		&.swiper-next
			right: -5px

.btn
	display: inline-flex
	align-items: center
	justify-content: center
	color: #fff
	height: r(50px)
	padding: 0 r(35px)
	text-transform: uppercase
	span
		margin-left: r(16px)
	&.btn-border
		border: 1px solid color(main)
		color: color(main)