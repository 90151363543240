.home-6
	background-image: url(../img/home-bg-3.jpg)
	background-size: cover
	background-repeat: no-repeat
	background-position: center
	.small-title
		font-weight: 500
		margin-bottom: 0
		text-transform: none
	.subscribefrm
		display: flex
		input
			flex: auto
			height: r(50px)
			padding: 0 15px
			margin-right: r(20px)
			background: rgba(#000 , 0.4)
			border: 1px solid rgba(#fff , 0.4)
			color: #fff
		button
			height: r(50px)
			width: r(200px)
			background: color(light-blue)
			color: #fff
			font-weight: 500
			text-transform: uppercase
			font-size: 1rem
			border: 0