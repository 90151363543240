.home-3
	background-image: url(../img/home-bg-2.jpg)
	background-size: cover
	background-repeat: no-repeat
	background-position: center
	.btn.btn-border
		border-color: #fff
		color: #fff

.home-project-slider-1
	position: relative

	.slider-btn-2
		position: absolute
		top: 48%
		transform: translateY(-50%)
		color: #fff
		z-index: 2
		font-size: r(40px)
		cursor: pointer
		&.slider-btn-prev
			right: -4.25%
		&.slider-btn-next
			right: -4.25%
			margin-top: 30px

	.slick-slide
		padding: 0 15px

	.slick-list
		margin: 0 -15px

	.item
		display: block
		position: relative
		.img
			+img-scale(610 / 1230 * 100%)
		.content
			position: absolute
			top: 50%
			transform: translateY(-50%)
			width: r(420px)
			padding: r(30px)
			display: flex
			flex-direction: column
			justify-content: center
			background: rgba(#fff , 0.95)
			left: r(75px)
		.title
			font-size: r(24px)
			font-weight: 700
			line-height: 1.375
		.desc
			color: #666666
			line-height: 1.5
			margin: r(16px) 0
	@media screen and ( max-width: 1600.98px )
		width: 75%
		.slider-btn-2
			top: 50%
			&.slider-btn-prev
				right: auto
				left: 15px
			&.slider-btn-next
				right: 15px
				margin-top: 0
	@media screen and ( max-width: 1024.98px )
		width: 100%
		.slider-btn-2
			display: none
	@media screen and ( max-width: 768.98px )
		.item
			.content
				position: static
				transform: none
				width: 100%

.home-project-slider-2
	position: absolute
	top: 0
	right: 0
	height: 100%
	width: 265px

	.slick-slider,.slick-list,.slick-track
		height: 100%

	.slick-slide
		padding: 0 10px
		> div
			height: 100%

	.slick-list
		margin: 0 -10px

	.item
		position: relative
		height: 100%
		.title
			position: absolute
			top: 50%
			left: 50%
			transform: translate(-50%,-50%) rotate(-90deg)
			color: #fff
			white-space: nowrap
			font-size: r(24px)
			font-weight: 600
		.img
			height: 100%
			background: color(light-blue)
			img
				opacity: 0.1
				height: 100%
				object-fit: cover
	@media screen and ( max-width: 1600.98px )
		position: static
		width: 25%
		padding-right: 15px
		height: auto
	@media screen and ( max-width: 1024.98px )
		width: 100%
		padding: 0 15px
		margin-top: r(20px)
		.item
			.img
				height: 100px
			.title
				transform: translate(-50%,-50%)
				white-space: initial
				line-height: 1.375
				padding: 0 15px
				text-align: center
				font-size: r(20px)
				width: 100%

.home-project-slider-wrap
	@media screen and ( max-width: 1600.98px )
		display: flex
	@media screen and ( max-width: 1024.98px )
		display: block
