.news-item
	.news-date
		font-size: r(14px)
		line-height: r(24px)
		margin-bottom: r(10px)
		color: #999999
	&.news-big
		position: relative
		display: block
		height: 100%
		.news-img
			height: 100%
			img
				width: 100%
				height: 100%
				object-fit: cover
			&::after
				position: absolute
				content: ''
				bottom: 0
				left: 0
				width: 100%
				height: 100%
				background: linear-gradient(0deg, rgba(0,0,0,0.75) 0%, rgba(0,0,0,0) 70%)
		.news-caption
			position: absolute
			bottom: 0
			left: 0
			width: 100%
			padding: r(50px)
			color: #fff
		.news-date
			color: #fff
		.news-title
			font-weight: bold
			font-size: r(20px)
			line-height: r(28px)
		.view-more
			margin-top: r(28px)
			color: color(light-blue)
	&.news-side
		display: flex
		.news-img
			flex: 0 0 180px
			height: 105px
			img
				width: 100%
				height: 100%
				object-fit: cover
		.news-caption
			flex: auto
			padding-left: r(30px)
		.news-date
			margin-bottom: 2px
		.news-title
			font-weight: 600
			line-height: r(24px)
			a
				+line(2)
		.view-more
			margin-top: r(16px)
		+ .news-side
			margin-top: r(26px)
			padding-top: r(26px)
			border-top: 1px solid rgba(0, 0, 0, 0.15)
	&.news-small
		.news-img
			a
				+img-scale(227 / 309 * 100%)
		.news-caption
			margin-top: r(20px)
		.news-date
			margin-bottom: 2px
		.news-title 
			font-weight: 600
			line-height: r(24px)
			a
				+line(2)
		.view-more
			margin-top: r(24px)
	@media screen and ( max-width: 768.98px )
		&.news-big
			.news-caption
				padding: r(20px)
			.view-more
				margin-top: 8px
	@media screen and ( max-width: 576px )
		&.news-big
			.news-img
				+img-scale(581 / 705 * 100%)
		&.news-side
			.news-img
				flex: 0 0 100px
				height: 80px
			.news-caption
				padding-left: r(15px)
			.view-more
				margin-top: 8px