header
	background: #fff
	padding: 6px 0

.logo
	@media screen and ( max-width: 1024.98px )
		img
			width: 54px

.menu
	> ul
		display: flex
		align-items: center
		> li
			+ li
				margin-left: r(40px)
			> a
				text-transform: uppercase
				font-weight: 600
				font-size: 16px
				color: color(main)
				display: inline-block
				line-height: 1.375
			&.active
				> a
					border-bottom: 2px solid color(light-blue)

	@media screen and ( max-width: 1280.98px )
		> ul
			> li
				+ li
					margin-left: r(24px)
				> a
					font-size: 14px
	@media screen and ( max-width: 1024.98px )
		> ul
			display: block
			> li
				+ li
					margin-left: 0
					margin-top: 24px
				> a
					color: #fff

.has-sub
	position: relative
	display: flex
	align-items: center
	> span
		margin-left: 5px
	.sub-menu
		display: none
		position: absolute
		top: 100%
		left: 0
		z-index: 2
		padding-top: 10px
		ul
			box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15)
			color: #333
			li
				line-height: 1.5
				a
					white-space: nowrap
					display: flex
					background: #fff
					align-items: center
					font-weight: 500
					font-size: r(14px)
					line-height: r(24px)
					padding: r(12px) r(20px) r(10px)
				&.active
					a
						background: color(light-blue)
						color: #fff
	@media screen and ( max-width: 1024.98px )
		justify-content: space-between
		flex-wrap: wrap
		> span
			font-size: 16px
		.sub-menu
			position: static
			width: 100%
			margin-top: 10px
			border-top: 0
			box-shadow: none
			ul
				background: transparent
				padding: 12px
				background: #fff
				font-size: 14px
	@media screen and ( min-width: 1024.98px )
		.toggle
			display: none
		&:hover
			.sub-menu
				display: block
		.sub-menu
			ul
				li
					a:hover
						text-decoration: underline

.language-wrapper
	margin-left: r(15px)
	padding-left: 15px
	border-left: 1px solid #acacac
	.language
		.current
			color: #666666
			text-transform: uppercase
			font-size: 13px
			font-weight: 600
			span
				margin-left: 8px
				font-size: 12px
		.dropdown-content
			padding-top: 10px
			min-width: 100%
			line-height: 1.75
			font-size: 13px
			ul
				padding: 8px 15px
	@media screen and ( max-width: 1024.98px )
		margin-left: 0
		padding-left: 0
		border-left: 0
		margin-top: 20px
		padding-top: 20px
		border-top: 1px solid #fff
		.language
			display: block
			.current
				color: #fff
				font-size: 16px
				display: flex
				justify-content: space-between
				align-items: center
			.dropdown-content
				color: #333
				font-size: 16px

.hotline-wrapper
	margin-left: 10px
	.hotline
		display: flex
		align-items: center
		justify-content: center
		min-width: r(110px)
		height: 30px
		background: color(red)
		color: #fff
		text-transform: uppercase
		font-size: 13px
		font-weight: 500
		padding: 0 10px
		em
			font-size: 12px
			margin-right: 8px

.search-wrapper
	margin-left: 10px
	.search-link
		display: flex
		align-items: center
		justify-content: center
		width: 30px
		height: 30px
		color: #666
		font-size: 12px
		background: #f1f3f5

.menu-toggle
	display: flex
	align-items: center
	justify-content: center
	width: 30px
	height: 30px
	color: #fff
	font-size: 12px
	background: color(light-blue)
	margin-left: 10px

.offcanvas-overlay
	position: fixed
	top: 0
	left: 0
	right: 0
	bottom: 0
	background: rgba(#000, 0.3)
	display: none
	z-index: 998

.menu-mobile-offcanvas
	color: #fff
	width: 270px
	left: -270px
	background: color(main)
	&.is-open
		transform: translate(270px,0,0)
	@media screen and ( min-width: 1024.98px )
		display: none
	.offcanvas-close
		display: none
	.offcanvas-wrap
		padding: r(40px) r(20px) r(40px)
		position: relative
