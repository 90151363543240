.about-1
	margin-top: r(40px)
	.img
		img
			width: 100%
	@media screen and ( max-width: 1024.98px )
		padding-bottom: r(40px)

.popup-more-content
	width: 1230px
	.img
		margin-bottom: r(30px)
	.content
		max-width: r(810px)
		margin: 0 auto
	@media screen and ( max-width: 576px )
		padding: 44px r(15px)