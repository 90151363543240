.cta-fixed-wrap
	position: fixed
	right: 10px
	bottom: r(120px)
	z-index: 88
	li
		position: relative
		z-index: 2
		+ li
			margin-top: r(10px)
		&:hover
			span
				opacity: 1
				pointer-events: auto
				transform: translateY(-50%)
	span
		position: absolute
		top: 0
		display: flex
		align-items: center
		height: r(50px)
		border-radius: r(25px)
		white-space: nowrap
		right: 0
		top: 50%
		z-index: -1
		padding-right: r(80px)
		padding-left: r(20px)
		box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.1)
		text-transform: uppercase
		font-weight: 700
		transition: 0.3s all
		opacity: 0
		background: #fff
		transform: translateX(50px) translateY(-50%)
		pointer-events: none
	.back-to-top
		display: flex
		align-items: center
		justify-content: center
		border-radius: 50%
		width: r(50px)
		height: r(50px)
		background: #2d4491
		color: #fff
		font-size: r(20px)
		margin: 0 auto
	.cta-button
		display: flex
		align-items: center
		justify-content: center
		width: r(50px)
		height: r(50px)
		border-radius: 50%
		background: #fff
		box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1)
		border: 1px solid #fff
		em
			font-size: r(20px)
			color: #fff
		&.cta-phone
			background: color(light-blue)
		&.cta-quotation
			background: color(light-blue)
	@media screen and ( max-width: 576px )
		.cta-button
			width: r(48px)
			height: r(48px)
		li
			a
				img
					max-width: r(48px)

.form-popup
	width: 960px
	.small-title
		margin-bottom: r(24px)