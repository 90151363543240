.career-intro-item
	.title
		font-weight: 600
		font-size: 30px
		line-height: 37px
		text-transform: uppercase
		color: color(main)
		margin-bottom: r(30px)
	.content-wrap
		max-height: r(310px)
		overflow-y: auto
		padding-right: r(20px)
		&::-webkit-scrollbar
			width: 5px
			background: #C4C4C4
		&::-webkit-scrollbar-thumb
			background: color(main)
	.img
		img
			width: 100%
	+ .career-intro-item
		margin-top: r(60px)
	&:nth-child(2n)
		.row
			flex-direction: row-reverse
	@media screen and ( max-width: 1024.98px )
		.content-wrap
			padding-right: 0
			max-height: none
		.title
			margin-bottom: r(20px)

.career-1
	padding-bottom: 0 !important

.career-2
	.productpager
		display: none

.job-item
	display: flex
	box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.15)
	.img
		flex: 0 0 40%
		img
			height: 100%
			object-fit: cover
	.content-wrap
		flex: 0 0 60%
	.title
		padding: r(25px) r(20px)
		font-weight: 600
		font-size: 20px
		line-height: 24px
		color: color(main)
		border-bottom: 1px solid #E0E0E0
	.content
		padding: r(20px)
		border-bottom: 1px solid #E0E0E0
		line-height: 1.4
		table
			width: 100%
			tr
				td
					width: 50%
					&:first-child
						font-weight: 700
					&:last-child
						text-align: right
						padding-left: 15px
				+ tr
					td
						padding-top: 1rem
	.view-more
		padding: r(20px)
	@media screen and ( max-width: 576px )
		display: block
		.img
			img
				width: 100%