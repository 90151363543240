//Reset
@import src/_plugins/sass-libraries/reset.sass

//Gridflex
$gl-gridName: row
$gl-gutter: r(30px)
$gl-gutter-vertical: r(30px)
$gl-mq-width: 'min-width'

@import src/_plugins/gridlex/src/gridlex.scss

// Global
body, html
	font-size: 13px
	line-height: 1
	font-family: 'Montserrat', sans-serif
	color: #333
	@media (min-width: 768px) 
		font-size: 14px
	@media (min-width: 1024px) 
		font-size: 15px
	@media (min-width: 1280px)
		font-size: 16px

*,*:before,*:after
	box-sizing: border-box
	outline: none

a
	text-decoration: none

.fa-exclamation-triangle
	line-height: 2.5
	color: #ff3333
	font-weight: 400
	font-family: 'Montserrat', sans-serif
	&:before
		font-family: 'Font Awesome 5 Pro'

.news-detail-page
	.subnav-wrapper
		display: none

.fb-like
	span
		width: 128px !important
		iframe
			width: 128px !important