.year-content-slider
	.item
		color: #fff
		.img
			background: #000
			height: 100%
			img
				opacity: 0.5
				height: 100%
				object-fit: cover
		.content
			position: absolute
			top: 50%
			left: 50%
			transform: translate(-50%,-50%)
			width: 100%
		.title
			text-align: center
			.small-title
				position: relative
				display: inline-block
				margin-bottom: r(27px)
				&::before
					content: url(../img/quote.png)
					position: absolute
					right: calc(100% + 10px)
					top: 10%
					transform: translateY(-50%)
	@media screen and ( min-width: 1024.98px )
		.item
			height: 644.22px
	@media screen and ( max-width: 1024.98px )
		.item
			color: #333
			.content
				position: static
				transform: none
				padding-top: r(50px)
			.img
				img
					opacity: 1

.year-dot-slider
	position: absolute
	bottom: 20px
	color: #fff
	left: 0
	width: 100%
	.swiper-wrapper
		align-items: center
	.item
		text-align: center
		transition: 0.3s all
		font-weight: 900
	.swiper-slide
		.item
			font-size: r(40px)
			opacity: 0.5
	.swiper-slide-prev,.swiper-slide-next
		.item
			font-size: r(50px)
			opacity: 0.7
	.swiper-slide-active
		.item
			font-size: r(70px)
			opacity: 1
	.swiper-btn-2
		position: absolute
		z-index: 2
		color: #fff
		top: 50%
		font-size: 30px
		transform: translateY(-50%)
		cursor: pointer
		&.swiper-prev
			left: 38%
		&.swiper-next
			right: 38%
	@media screen and ( max-width: 1024.98px )
		color: #333
		position: relative
		margin-top: r(40px)
		transform: none
		.swiper-navigation
			display: none
		.swiper-btn-2
			color: #000