.career-detail-heading
	display: flex
	align-items: center
	justify-content: space-between
	margin-bottom: r(30px)
	.title
		font-weight: 600
		font-size: 30px
		line-height: 37px
		text-transform: uppercase
		color: color(main)

.career-detail-info
	padding: r(30px)
	box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.15)
	margin-bottom: r(60px)
	.img
		+img-scale(328 / 494 * 100%)
	table
		width: 100%
		tr
			td
				width: 50%
				padding: 1rem 0
				&:first-child
					font-weight: 700
				&:last-child
					padding-left: 15px
			+ tr
				td
					border-top: 1px solid #E0E0E0

.career-detail-content
	padding-bottom: r(30px)
	border-bottom: 1px solid #E0E0E0
	h2
		font-size: r(22px)
		font-weight: 500
		text-transform: uppercase
		color: color(main)

.career-detail-group
	margin-top: r(30px)
	display: flex
	align-items: center
	flex-wrap: wrap
	margin-bottom: r(-15px)
	> *
		margin-right: r(15px)
		margin-bottom: r(15px)
	.btn
		min-width: r(200px)
		text-transform: none

.other-career
	.other-title
		padding: 0 r(20px)
		display: flex
		align-items: center
		justify-content: center
		height: r(70px)
		background: color(light-blue)
		color: #fff
		font-weight: 600
		font-size: 24px
		line-height: 24px
	.other-list
		padding: r(20px)
		border: 1px solid #C4C4C4
		border-top: 0
		ul
			li
				line-height: 1.5
				.title
					font-weight: 500
				.date
					color: #666
				+ li
					margin-top: r(20px)
					padding-top: r(20px)
					border-top: 1px solid #C4C4C4



.dialogpage
	margin: 0 auto
.apply-frm
	position: relative
	margin: 0
	background: #fff	
	line-height: 1.375
	padding: r(50px)
	input[type="file"]
		left: 0
	h4
		font-size: r(24px)
		color: color(main)
		font-weight: 700
		margin-bottom: r(24px)

	.row
		margin-left: r(-20px)
		margin-bottom: r(-20px)
		> *
			padding-left: r(20px)
			padding-bottom: r(20px)

	.fa-exclamation-triangle[style="display: inline;"]
		display: block !important
		top: 50%
		transform: translateY(-50%)
		right: 15px
		color: red
		position: absolute
	.label
		font-size: 14px
		font-weight: 400
		margin-bottom: 10px
		display: none
		.required
			display: inline-block
	.attachfile1
		.label
			display: inline-block
			.required
				display: inline
	.attachfile2
		margin-top: 15px
	input[type="text"], input[type="password"], textarea
		width: 100%
		border: 1px solid #c8c8c8
		padding: 0 r(20px)
		font-size: 1rem
		&::placeholder
			color: #999
	input[type="text"]
		height: r(53px)
	textarea
		height: r(153px)
		padding-top: r(12px)
	.RadAsyncUpload
		width: auto
		.ruDropZone
			display: none
		.ruFileWrap
			position: relative
			display: block
			height: 45px
		.ruFileInput, .ruBrowse
			width: r(190px)
			height: r(40px)
			background: color(light-blue)
			color: #fff
			text-transform: uppercase
			font-size: 14px
			display: flex
			justify-content: center
			align-items: center
			cursor: pointer
			border: 0
		.ruFileInput
			position: absolute
			top: 0
			opacity: 0

	h3
		font-weight: 700
		padding: 0 20px
		font-size: 18px

	.frm-btn
		text-align: center
		input[type="submit"]
			width: r(140px)
			height: r(45px)
			border: 1px solid #999
			font-size: 14px
			font-weight: 700
			cursor: pointer
			background: color(main)
			color: #fff
	@media screen and ( max-width: 1024.98px )
		padding: r(30px)

.recruitment-detail-page
	.sub-banner
		display: none
	.fancybox-iframe
		width: 992px !important