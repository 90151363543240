.about-nav
	background: #fff
	ul
		padding: r(20px) 0 0
		display: flex
		justify-content: space-between
		line-height: 1.5
		text-transform: uppercase
		font-weight: 600
		border-bottom: 1px solid rgba(0, 0, 0, 0.25)
		overflow: auto
		li
			+ li
				padding-left: 15px
			a
				display: block
				position: relative
				padding-bottom: r(15px)
				white-space: nowrap
				&:after
					content: ''
					position: absolute
					width: 100%
					height: 3px
					background: color(light-blue)
					bottom: 0
					left: 0
					opacity: 0
					transition: 0.3s all
			&.active
				a
					&:after
						opacity: 1