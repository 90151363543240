.service-detail
	.img
		margin-bottom: r(40px)
		img
			width: 100%

.other-service
	.title
		padding: 0 r(20px)
		display: flex
		align-items: center
		justify-content: center
		height: r(70px)
		background: color(light-blue)
		color: #fff
		font-weight: 600
		font-size: 24px
		line-height: 24px
	.other-list
		padding: r(20px)
		border: 1px solid #C4C4C4
		border-top: 0
		ul
			li
				line-height: 1.5
				+ li
					margin-top: r(20px)
					padding-top: r(20px)
					border-top: 1px solid #C4C4C4