.home-banner
	position: relative
	.swiper-navigation
		position: absolute
		top: 50%
		transform: translateY(-50%)
		width: 100%
		z-index: 2
		.container
			position: relative
	.banner
		position: relative
		.content
			position: absolute
			top: 50%
			left: 0
			width: 100%
			color: #fff
			transform: translateY(-50%)
		.title
			font-weight: 600
			line-height: r(22px)
			text-transform: uppercase
			margin-bottom: r(20px)
		.desc
			font-size: r(36px)
			line-height: r(46px)
	@media screen and ( max-width: 768.98px )
		.banner
			.img
				+img-scale(56.25%)
			.content
				top: auto
				transform: none
				bottom: 15px
			.title
				margin-bottom: r(8px)
			.desc
				font-size: r(20px)
				line-height: 1.375