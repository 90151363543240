footer
	padding: r(40px) 0 r(50px)
	background: color(light-blue)
	color: #fff
	line-height: 1.375

.footer-menu
	padding-bottom: r(24px)
	border-bottom: 1px solid rgba(#fff , 0.15)
	ul
		margin-left: r(-20px)
		margin-bottom: r(-20px)
		li
			display: inline-block
			padding-left: r(20px)
			padding-bottom: r(20px)
			a
				text-transform: uppercase
				font-size: 12px

.footer-info
	margin-top: r(24px)
	font-size: 12px
	color: rgba(#fff , 0.8)
	p
		+ p
			margin-top: r(12px)

.footer-social
	margin-top: r(24px)
	display: flex
	align-items: center
	ul
		margin-right: r(20px)
		li
			display: inline-block
			a
				height: 26px
				width: 26px
				display: inline-flex
				align-items: center
				justify-content: center
				background: #fff
				span
					&.fa-facebook-f
						color: #005db0
					&.fa-youtube
						color: #db0000
			+ li
				margin-left: 8px