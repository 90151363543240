.news-detail-title
    font-weight: 600
    font-size: r(30px)
    line-height: r(46px)

.news-detail-meta
    display: flex
    align-items: center
    justify-content: space-between
    margin-top: r(20px)
    padding-bottom: r(10px)
    border-bottom: 1px solid rgba(0, 0, 0, 0.25)
    .news-date
        line-height: r(24px)

.news-detail-content
    padding-top: r(28px)
    padding-bottom: r(40px)
    border-bottom: 1px solid rgba(0, 0, 0, 0.25)
        
.news-detail-other
    margin-top: r(60px)
    @media screen and ( max-width: 1024.98px )
        margin-top: r(40px)